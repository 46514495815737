<template>
  <div class="h-100 d-flex flex-column tabs">
    <ul v-on:wheel="scrollThroughBar" class="mr-2" style="display: inherit">
      <li class="nav-item mr-2 noselect" style="display: inline-block">
        <span class="click-cursor m-2">
          <span
            @click="nextTab(false)"
            :class="buttonClasses()"
            class="click-cursor"
          >
            &lt;
          </span>
        </span>
      </li>
      <simplebar
        data-simplebar-auto-hide="false"
        style="
          white-space: nowrap;
          overflow: auto;
          width: 100%;
          text-align: center;
        "
        ref="tabsScrollbar"
        id="tabBar"
      >
        <!-- <div  style="white-space:nowrap;overflow:auto;"> -->
        <li
          class="nav-item"
          style="display: inline-block"
          v-for="(tab, index) in tabs"
          :key="index"
        >
          <span
            class="click-cursor m-2 mr-2"
            @click="changeTab(index)"
            v-if="tab.active"
          >
            <span :class="buttonClasses(index)">
              {{ tab.label }}
            </span>
          </span>
        </li>
        <!-- </div> -->
      </simplebar>
      <li class="nav-item mr-2" style="display: inline-block">
        <span class="click-cursor m-2">
          <span
            @click="nextTab()"
            :class="buttonClasses()"
            class="click-cursor"
          >
            &gt;
          </span>
        </span>
      </li>
    </ul>

    <div class="h-100">
      <simplebar
        ref="sidepanel"
        class="sidebar-simplebar h-100"
        data-simplebar-auto-hide="false"
      >
        <div
          :style="{ display: currentTab === index ? 'block' : 'none' }"
          :key="index"
          class="pr-5 pl-5"
          v-for="(tab, index) in tabs"
        >
          <slot :name="'tab' + index" v-if="tab.active"> Loading... </slot>
        </div>
      </simplebar>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "Tabs",
  components: {
    simplebar,
  },
  props: ["tabs", "active"],
  data() {
    return {
      currentTab: this.active || 0,
    };
  },
  mounted() {
    this.setup();
  },
  methods: {
    setup: function () {
      const activeTabs = this.tabs.filter((tab) => tab.active);
      if (activeTabs.length > 0)
        this.currentTab = this.tabs.indexOf(activeTabs[0]);
      else this.currentTab = -1;

      this.$refs.tabsScrollbar.$el.children[1].style.display = "none";

      this.setupScrollbar();
    },

    buttonClasses: function (index) {
      const list = ["noselect", "tab-opacity-05"];
      if (index !== undefined) {
        if (index === this.currentTab) {
          list.push(["font-weight-bold", "tab-opacity-1"]);
        }
      }
      return list;
    },
    changeTab: function (index) {
      if (index === this.tabs.length) {
        index = 0;
        this.$refs.tabsScrollbar.SimpleBar.getScrollElement().scrollLeft = 0;
      } else if (index < 0 || index === this.tabs.length - 1) {
        index = this.tabs.length - 1;
        this.$refs.tabsScrollbar.SimpleBar.getScrollElement().scrollLeft = 533;
      } else {
        this.$refs.tabsScrollbar.SimpleBar.getScrollElement().scrollLeft =
          (533 / 10) * index;
      }
      this.currentTab = index;
      this.$emit("update:active", index);
    },
    scrollThroughBar: function (event) {
      if (event.deltaY > 0) {
        this.nextTab();
        // this.changeTab(this.currentTab + 1);
      } else if (event.deltaY < 0) {
        this.nextTab(false);
        // this.changeTab(this.currentTab - 1);
      }
    },
    setupScrollbar: function () {
      const ref = this.$refs.sidepanel.SimpleBar;
      const productsScrollbar = ref.getScrollElement();
      //productsScrollbar.addEventListener("scroll", this.onScroll);
      //this.$store.commit("toggleSidebar", true);
      productsScrollbar.style.overflow = "hidden scroll";
      ref.width = 20;
      ref.options.forceVisible = true;
    },
    nextTab: function (dir = true) {
      const currActiveTab = this.activeTabs.filter((tab) => {
        return tab.index === this.currentTab;
      })[0];
      const currActiveIndex = this.activeTabs.indexOf(currActiveTab);
      if (dir) {
        //next
        let nextIndex = currActiveIndex + 1;
        if (nextIndex === this.activeTabs.length) nextIndex = 0;
        this.changeTab(this.activeTabs[nextIndex].index);
      } else {
        //back
        let prevIndex = currActiveIndex - 1;
        if (prevIndex < 0) prevIndex = this.activeTabs.length - 1;
        this.changeTab(this.activeTabs[prevIndex].index);
      }
    } /*,
    onScroll: function(scrollbar) {
      scrollbar.target = null;
      //console.log(scrollbar.target);
    }*/,
  },
  computed: {
    activeTabs: function () {
      const activeTabs = [];
      for (let index = 0; index < this.tabs.length; index++) {
        const tab = this.tabs[index];
        if (tab.active) {
          tab.index = index;
          activeTabs.push(tab);
        }
      }
      return activeTabs;
    },
  },
  watch: {
    tabs: function () {
      this.setup();
    },
    active: function (value) {
      this.currentTab = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.tabs {
  color: #a6a6a6;
  font-family: "Roboto";
}
.tab-opacity-1 {
  opacity: 1 !important;
}
.tab-opacity-05 {
  opacity: 0.5;
}

span::selection {
  background: transparent;
}

.simplebar-track::after {
  bottom: 1vh !important;
}

.scroller {
  overflow-y: scroll;
}
</style>
